// Variable overrides
$body-bg: #ffffff;

$theme-colors: (
        "primary": #3071b3
);

$pagination-color: #3071b3;
$pagination-active-bg: #3071b3;

@import "~bootstrap/scss/bootstrap";
@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;
  font-size: 87.5%;
  line-height: 1.5;
}

// Needed for the sticky footer:
.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header, footer {
  flex-shrink: 0;
}

.main {
  flex-grow: 1;
}

footer {
  background-color: #292929;
  color: rgba(255,255,255,0.65);
  padding: 4em 1.5vw 2.5em 1.5vw;
  flex: 0 0 auto;
}
// end of sticky footer
// banner
.banner {
  background-image: url("../../assets/images/jetSliceImage.jpg");
  min-height: 90px;
  background-size: 100%;
  label {
    padding: 0.45rem;
    color: white;
  }
  input[name=jump_to_shot_id] {
    min-width: 70px;
    max-width: 85px;
  }
}
.banner-west {
  background-image: url("../../assets/images/westSliceImage.png");
}

.main-image-holder {
  background-image: url("../../assets/images/3D10.06-4c.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 }

.main-image-holder-west {
  background-image: url("../../assets/images/west.png");
}

header > .container-fluid > .row {
  border-bottom: 1px solid black;
}

.not-banner {
  min-height: 90px;
  &>.col {
    background-color: rgba(0,0,0,0.5);
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 0.25rem;
    margin: 0 15px;
  }
}
.banner, .not-banner {
  a {
    color: white;
  }
}

.brand-div {
  padding-left: 0;
}
// end of banner

// top bar
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  a {
    img {
      height: 40px;
      margin-top: -14px;
      margin-bottom: -9px;
    }
  }
}

.hide-me {
  visibility: hidden;
}

.jump-to-shot {
  max-width: 280px;
}

// end of top bar

/*** Notifications ***/
/*** Example rules ***/
.redux-toastr .toastr {
  border-radius: 0px;
  .rrt-title {
  }
  .rrt-text {
  }
  &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error, & button {
  }
  &.rrt-error {
  }
  &.rrt-success {
  }
  &.rrt-warning {
  }
  &.rrt-info {
  }
  .close-toastr {
    opacity: 0.6;
  }
}

.accordion {
  .card-header {
    padding: .1rem .5rem;
  }

  .card:not(:last-child) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.zebra-odd {
  background-color: #efefef;
}

.zebra-even {
  background-color: #fff;
}

.btn-bg-image {
  display: inline-block;
  background-repeat: no-repeat;
  border: none;
  outline: none !important;
  width: 23px;
  height: 23px;

  &:hover {
    background-color: #ddeeff;
  }
}

.btn-open {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-arrow-right-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path fill-rule="evenodd" d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/><path fill-rule="evenodd" d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"/></svg>')
}

.btn-close {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-arrow-left-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path fill-rule="evenodd" d="M8.354 11.354a.5.5 0 0 0 0-.708L5.707 8l2.647-2.646a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708 0z"/><path fill-rule="evenodd" d="M11.5 8a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5z"/></svg>');
}

.btn-annotate {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-pencil-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>');
  width: 26px;
  height: 26px;
}

.btn-annotate-green {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-pencil-square" fill="%2300ff00" xmlns="http://www.w3.org/2000/svg"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>');
  width: 26px;
  height: 26px;
}

.btn-collapse-open {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-dash-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>  <path fill-rule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z"/></svg>');
}

.btn-collapse-closed {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-plus-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/> <path fill-rule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/> <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/> </svg>');
}

.btn-remove {
  background-image: url('data:image/svg+xml, <svg viewBox="0 0 16 16" class="bi bi-x-square" fill="%230071b3" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/><path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/></svg>');
}

.chart-label {
  font-size: .7rem;
}

.chart-wrapper {
  position: relative;
}

.download-chart-btn {
  color: #0071b3;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;

  &:hover {
    color: lighten(#0071b3, 10%);
  }

  &:focus {
    border: none;
    outline: none;
  }
}

.camera-icon {
  width: 23px;
  height: 23px;
}

.remove-chart-btn {
  position: absolute;
  left: 30px;
  top: 0;
  z-index: 999;
}

.filtered-charts {
  position: relative;
}

.btn-toggle-lower {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pagination-input {
  font-size: inherit;
  border-radius: 0;
}

.pagination-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background-color: #3071b3;
  border: 1px solid #3071b3;
  font-size: inherit;
}

.no-results {
  font-size: 1.5rem;
}

.experiment-view-search-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.variables-values-wrapper {
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}

.compare-modal {
  .modal-content {
    width: 1200px;
  }
}

.no-data-message {
  position: absolute;
  left: 50%;
  top: 60px;
  font-size: 1rem;
  font-weight: 500;
}

.delete-annotation-btn {
  background: #fff;
  border: 0;
  font-size: 1.25rem;
  color: #545b62;
  outline: none !important;
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  &:hover {
    background-color: lighten(#545b62, 60%);
  }
}

.list-animation-item-enter {
  opacity: 0;
}

.list-animation-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.list-animation-item-exit {
  opacity: 1;
}

.list-animation-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.trash-icon-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.icon {
  &-sm {
    font-size: 1rem;
  }

  &-md {
    font-size: 1.25rem;
  }

  &-lg {
    font-size: 1.5rem;
  }
}

.btn-border-gray {
  border-color: #ced4da !important;
}

.unselectable {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.entry-data-not-found-alert {
  padding: 0.2rem 1.25rem;
}

.min-w-110 {
  min-width: 110px;
}

.fair-data-modal {
  .modal-content {
    max-height: 80vh;
  }

  .modal-body {
    overflow: auto;
  }
}

li.list-group-item.selected {
  background-color: lightblue;
}

.pedestal-entry:not(:first-child) {
  margin-top: 1rem !important;
}

.wrong-import {
  box-shadow: inset -1px 1px 6px 0px red, inset 1px -1px 6px 0px red;
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1540px;
  }
}

.custom-tooltip {
  border: 1px solid gray;
  padding: 10px 10px 0 10px;
  background-color: white;
}

.label-parent:hover .label-definition {
  visibility: visible !important;
}

.ac-select-container {
  width: 100%;
  .ac-select__menu {
    width: 600px;
    z-index: 1000;
    right: 0;
  }
}
#variables {
  width: 94%;
  .ac-select__menu {
    width: 100%;
  }
}

